import React from "react";
import "./Contactus.scss";
import { Button, Col, Form, Input, message, Row, Typography } from "antd";
import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { Enquiry } from "../../../Services/Api/ApiFile";
const { Paragraph } = Typography;
const { TextArea } = Input;

const Contactus = () => {
  const schema = yup
    .object({
      name: yup.string().required("This Field is Required"),
      companyName: yup.string().required("This Field is Required"),
      phoneNumber: yup
        .string()
        .matches(/^[0-9]+$/, "Phone number must be numeric")
        .length(10, "Phone number must be exactly 10 digits")
        .required("This Field is Required"),
      emailId: yup
        .string()
        .email("Invalid email address")
        .required("This Field is Required"),
      message: yup.string(),
    })
    .required();

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      name: "",
      companyName: "",
      phoneNumber: "",
      emailId: "",
      message: "",
    },
    validationSchema: schema,
    onSubmit,
  });

  async function onSubmit(values, actions) {
    console.log(values);
    try {
      await Enquiry(values);
      message.success("Successfull");
    } catch (error) {
      console.log(error.message);
      message.error("Server error");
    } finally {
      resetForm();
    }
  }
  return (
    <div className="contactusMainPage">
      <Typography.Title level={1} className="TitleText">
        TALK TO OUR LOGISTICS <span style={{ color: "#ff6a1a" }}>EXPERT</span>
      </Typography.Title>
      <Paragraph className="sentence">
        Schedule a call with us to join India’s leading ecommerce companies and
        become a Vitran partner.
      </Paragraph>
      <form className="contactForm" onSubmit={handleSubmit}>
        <Row className="formValues" gutter={[16, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              layout="vertical"
              validateStatus={errors.name && touched.name ? "error" : ""}
              help={errors.name && touched.name ? errors.name : null}
              style={{ fontWeight: 500 }}
            >
              <Input
                id="name"
                name="name"
                value={values.name}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Name"
                className="inputText"
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              layout="vertical"
              validateStatus={
                errors.companyName && touched.companyName ? "error" : ""
              }
              help={
                errors.companyName && touched.companyName
                  ? errors.companyName
                  : null
              }
              style={{ fontWeight: 500 }}
            >
              <Input
                id="companyName"
                name="companyName"
                value={values.companyName}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Company Name"
                className="inputText"
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="formValues" gutter={[16, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              layout="vertical"
              validateStatus={
                errors.phoneNumber && touched.phoneNumber ? "error" : ""
              }
              help={
                errors.phoneNumber && touched.phoneNumber
                  ? errors.phoneNumber
                  : null
              }
              style={{ fontWeight: 500 }}
            >
              <Input
                id="phoneNumber"
                name="phoneNumber"
                value={values.phoneNumber}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Phone Number"
                className="inputText"
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              layout="vertical"
              validateStatus={errors.emailId && touched.emailId ? "error" : ""}
              help={errors.emailId && touched.emailId ? errors.emailId : null}
              style={{ fontWeight: 500 }}
            >
              <Input
                id="emailId"
                name="emailId"
                value={values.emailId}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Email"
                className="inputText"
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item layout="vertical" style={{ fontWeight: 500 }}>
              <TextArea
                id="message"
                name="message"
                value={values.message}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Message"
                className="textarea"
                size="large"
                rows={4}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <Button size="large" className="submitBtn" htmlType="submit">
            Submit
          </Button>
        </Row>
      </form>
    </div>
  );
};

export default Contactus;
