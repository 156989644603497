import React, { useState } from "react";
import "./HomePage.scss";
import HomeNav from "./Home-components/HomeNav/HomeNav";
import HomePageHeadImage from "../images/homepage-head-image.jpg";
import sakshamImage1 from "../images/sakshamimage1.jpg";
import sakshamImage2 from "../images/sakshamimage2.jpg";
import sakshamImage3 from "../images/sakshamimage3.jpg";
import sakshamImage4 from "../images/sakshamimage4.png";
import ThumpsUp from "../images/thumpsup.svg";
import FourWheeler from "../images/4wheeler.svg";
import Tech from "../images/tech.svg";
import Rocket from "../images/Rocket.svg";
import { Button, Col, Collapse, Row, Typography } from "antd";
import ScrollTrigger from "react-scroll-trigger";
import Amazon from "../Video/AmazonVideo.mp4";
import DeliveringStatesMap from "./Home-components/DeliveringStatesMap/DeliveringStatesMap";
import HomeFooter from "./Home-components/HomeFooter/HomeFooter";
import { useNavigate } from "react-router-dom";
const { Paragraph } = Typography;

const HomePage = () => {
  const navigate = useNavigate();
  const [countUp, setCountUp] = useState(false);
  const flexible_plug_items = [
    {
      id: 1,
      title: "Flexible Delivery models",
      text: "First mile, middle mile, reverse pick up and last mile delivery services.",
    },
    {
      id: 2,
      title: "Quick deployment",
      text: "Prompt set-up for new business and scale",
    },
    {
      id: 3,
      title: "Fast scale up",
      text: "Address peaks and demand fluctuations",
    },
    {
      id: 4,
      title: "Warehousing solutions",
      text: "Elastic storage and distribution facilities",
    },
    {
      id: 5,
      title: "Hyperlocal support",
      text: "On demand and scheduled delivery partners for hyperlocal services",
    },
    {
      id: 6,
      title: "Customer Efficiency",
      text: "Empower customers to achieve business goals",
    },
  ];

  const sakshamImages = [
    { img: sakshamImage1, id: 1 },
    { img: sakshamImage2, id: 2 },
    { img: sakshamImage3, id: 3 },
    { img: sakshamImage4, id: 4 },
  ];

  const Accordionitems = [
    {
      key: "1",
      label: "What areas do Vitran delivery services cover?",
      children: (
        <p>
          We are currently operational in over 60+ cities and towns across
          Uttarakhand, Uttar Pradesh, Punjab, Haryana, Himachal Pradesh, and
          NCR.
        </p>
      ),
    },
    {
      key: "2",
      label: "Does Vitran have its own delivery workforce and fleet?",
      children: (
        <p>
          Yes, we have our own dedicated workforce and fleet. Our team is
          trained and deployed based on client requirements, allowing us to
          swiftly launch new operations and scale efficiently during peak
          demand.
        </p>
      ),
    },
    {
      key: "3",
      label: "What types of deliveries does Vitran specialize in?",
      children: (
        <p>
          We specialize in both last mile delivery for e-commerce businesses and
          hyperlocal delivery services for vendors, ensuring swift and efficient
          distribution of goods.
        </p>
      ),
    },
    {
      key: "4",
      label: "Can Vitran handle bulk deliveries for businesses?",
      children: (
        <p>
          Absolutely. We have the capacity and expertise to handle bulk
          deliveries for businesses of all sizes. Our tailored solutions cater
          to the unique needs of each client and our technology easily
          integrates with existing ERP systems.
        </p>
      ),
    },
    {
      key: "5",
      label: "What sets Vitran apart from other delivery services?",
      children: (
        <p>
          At Vitran, we pride ourselves on our commitment to reliability, speed,
          and customer satisfaction. Our dedicated workforce, technology, and
          personalized solutions and ability to service far-flung and hard to
          reach areas such as Uttarkashi distinguish us as a preferred choice
          for last mile and hyperlocal deliveries.
        </p>
      ),
    },
    {
      key: "6",
      label: "How does Vitran ensure sustainability in its operations?",
      children: (
        <p>
          We are committed to sustainability. Our fleet is equipped with
          eco-friendly vehicles, and we are continually implementing
          environmentally conscious practices and electric vehicles to minimize
          our carbon footprint in the last mile delivery process.
        </p>
      ),
    },
  ];
  return (
    <div className="homePageMaindiv">
      <HomeNav />
      <div className="headSection">
        <div className="headimagediv">
          <img src={HomePageHeadImage} alt="Homepage-headimage" />
        </div>
        <div className="headTextRight">
          <Typography.Title level={1} className="headTitleText">
            WE <span style={{ color: "#ff6a1a" }}>D</span>ELIVER.
          </Typography.Title>
          <Typography.Title level={1} className="headTitleText">
            WE <span style={{ color: "#ff6a1a" }}>D</span>ISTRIBUTE.
          </Typography.Title>
          <Paragraph className="headParaText">
            We empower e-commerce, logistics and hyperlocal companies by setting
            up quick-scale delivery models that address variations in demand and
            enable digital transformation.
          </Paragraph>
          <div className="right_contactbtn">
            <Button onClick={() => navigate("/contact-us")}>Contact-us</Button>
          </div>
        </div>
      </div>
      <div className="flexible_plug-div">
        <Typography.Title level={1} className="headTitle">
          A <span style={{ color: "#ff6a1a" }}>Flexible</span> Plug And Play
          Logistics Network.
        </Typography.Title>

        <div className="flexibleplug-content-Mainbox">
          {flexible_plug_items.map((ele) => {
            return (
              <div className="contentBox" key={ele.id}>
                <Typography.Title level={4}>{ele.title}</Typography.Title>
                <Paragraph className="flexbleplug-para">{ele.text}</Paragraph>
              </div>
            );
          })}
        </div>
      </div>
      {/* *********************Saksham****************** */}
      <div className="sakshamMaindiv">
        <div className="sakshamImagesBox">
          {sakshamImages.map((image) => (
            <div className="sakshamImageContainer" key={image.id}>
              <img
                src={image.img}
                alt={`saksham-${image.id}`}
                className="sakshamImage"
              />
            </div>
          ))}
        </div>
        <div className="sakshamContentBox">
          <Typography.Title level={1} className="sakshamTitleText">
            SAKSHAM
          </Typography.Title>
          <Paragraph className="sakshamPara">
            Saksham, our last mile delivery service provides highly effective
            and cost-efficient services in North India. With our own warehouses,
            well-trained fieldforce and network of delivery vans, our Saksham
            teams are easily integrated in client operations using world-class
            processes and cutting-edge technology.
          </Paragraph>
        </div>
      </div>

      {/* **********************DeliveringStatesMapStatus***************** */}
      <ScrollTrigger
        onEnter={() => setCountUp(true)}
        onExit={() => setCountUp(false)}
      >
        <DeliveringStatesMap countUp={countUp} />
      </ScrollTrigger>

      {/* **********************Why choose us***************************** */}
      <div className="why-chooseus_div">
        <Typography.Title level={1} className="whychooseusTitle">
          WHY CHOOSE <span style={{ color: "#ff6a1a" }}>US</span>
        </Typography.Title>

        <div className="whychoose_ContentBox">
          {[
            {
              id: 1,
              img: ThumpsUp,
              content: "Deliver Exceptional Experience & Customer Satisfaction",
            },
            {
              id: 2,
              img: FourWheeler,
              content: "Drive Logistics Effeciency",
            },
            {
              id: 3,
              img: Tech,
              content: "Cutting Edge Technology",
            },
            {
              id: 4,
              img: Rocket,
              content: "Launch And Scale Innovative Delivery Channels",
            },
          ].map((ele) => {
            return (
              <div className="box" key={ele.id}>
                <img
                  src={ele.img}
                  alt="whychooseusimg"
                  className="whychooseimages"
                />
                <Paragraph className="whychooseText">{ele.content}</Paragraph>
              </div>
            );
          })}
        </div>
      </div>

      {/* *************************Delivering Excellence************************ */}
      <div className="delivering_MainBox">
        <Typography.Title level={1} className="deliveringTitle">
          DELIVERING <span style={{ color: "#ff6a1a" }}>EXCELLENCE</span>
        </Typography.Title>
        <Paragraph className="deliveringpara">
          Watch the determination of our team as they navigate treacherous
          trails, bridging the gap between remote communities and essential
          supplies in remote areas.
        </Paragraph>
        <div className="videoMainBox">
          <Row gutter={[16, 16]} className="antrow">
            <Col xs={24} sm={24} md={24} lg={12} xl={12} className="Leftside">
              <Typography.Title level={5} className="videoPara">
                <i>
                  “It has been a fulfilling experience. With Amazon’s support,
                  we have set up a highly efficient operational team. This has
                  allowed us to serve our customers well in all the territories
                  in which we currently operate. I want to continue growing with
                  Amazon as we expand to other geographical locations over the
                  next 3 years.”
                </i>
              </Typography.Title>
              <span className="ceoName">-Rahul Kesar | CEO Vitran</span>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              className="videoContentRightside"
            >
              <video controls>
                <source src={Amazon} type="video/mp4" />
              </video>
              <p className="videoDetails">
                Video Credits:{" "}
                <a
                  href="https://www.aboutamazon.in/news/operations/delivering-amazon-packages-in-himalayas?utm_source=paid&utm_medium=ani&utm_term=datw"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i>
                    <strong>Amazon News India</strong>
                  </i>
                </a>{" "}
              </p>
            </Col>
          </Row>
        </div>
      </div>

      {/* ***************************Frequently asked questions***************************** */}

      <div className="frequentlyasked_questionsBox">
        <Typography.Title level={1} className="faqTitle">
          FREQUENTLY ASKED <span style={{ color: "#ff6a1a" }}>QUESTIONS</span>
        </Typography.Title>
        <Collapse
          className="homepageCollapse"
          accordion
          items={Accordionitems}
          expandIconPosition="end"
        />
      </div>

      {/* *********************Footer************************ */}
      <HomeFooter />
    </div>
  );
};

export default HomePage;
