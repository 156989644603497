import React from "react";
import NoData from "../../../components/NoData/NoData";
import ThreeWheeler from "../../../images/three wheeler 1.png";
import TwoWheeler from "../../../images/motorcycle 1.png";
import FourWheeler from "../../../images/express-delivery 1.png";
import { Col, Image, Row, Typography } from "antd";
import dayjs from "dayjs";
import PreviewImage from "../../../components/PreviewImage/PreviewImage";
import { useNavigate } from "react-router-dom";
const { Paragraph } = Typography;

const OrderDataCard = ({ orderData, orderStatus }) => {
  const navigate = useNavigate();
  return (
    <div className="orderDeliveryReq-mainBox">
      {orderData.length === 0 ? (
        <NoData />
      ) : (
        orderData.map((el, index) => {
          return (
            <div key={index}>
              <Row
                style={{
                  backgroundColor:
                    el?.deliverystatus === "Delivered"
                      ? "rgba(0, 201, 76, 0.12)"
                      : el?.deliverystatus === "Cancelled"
                      ? "rgba(78, 78, 78, 0.12)"
                      : "rgba(244, 109, 37, 0.12)",
                  borderRadius: "5px",
                  marginTop: index === 0 ? 0 : "10px",
                  padding: "10px",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/orderData/${el.id}/${el.orderId}`);
                }}
              >
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Typography.Text style={{ margin: 0, fontWeight: 600 }}>
                    {orderStatus === "All" && ` - ${el.deliverystatus}`}{" "}
                  </Typography.Text>
                </Col>

                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Pickup Date
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    {dayjs(el.pickupdate).format("DD-MM-YYYY")}
                  </Paragraph>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Pickup Slot
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>{el.pickupSlot}</Paragraph>
                  {el.deliverystatus === "Delivered" && (
                    <div>
                      <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                        PickUp Image
                      </Paragraph>
                      <PreviewImage src={el.pickupImage} alt="Pickup" />
                    </div>
                  )}
                </Col>
                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Delivery Date
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    {dayjs(el.deliveryDate).format("DD-MM-YYYY")}
                  </Paragraph>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Delivery Slot
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    {el.deliveryTimeSlot}
                  </Paragraph>
                  {el.deliverystatus === "Delivered" && (
                    <div>
                      <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                        Drop Image
                      </Paragraph>
                      <PreviewImage src={el.deliveryImage} alt="DropImage" />
                    </div>
                  )}
                </Col>

                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                  <div>
                    <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                      Order Id
                    </Paragraph>
                    <Paragraph style={{ margin: 0 }}>{el.orderId}</Paragraph>
                  </div>

                  {el.vehicleType === "Three Wheeler" && (
                    <div>
                      <Image src={ThreeWheeler} alt="icon" preview={false} />
                    </div>
                  )}
                  {el.vehicleType === "Two Wheeler" && (
                    <div>
                      <Image src={TwoWheeler} alt="icon" preview={false} />
                    </div>
                  )}
                  {el.vehicleType === "Four Wheeler" && (
                    <div>
                      <Image src={FourWheeler} alt="icon" preview={false} />
                    </div>
                  )}
                  {el.type === "vendor" && (
                    <div>
                      <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                        Vendor code
                      </Paragraph>
                      <Paragraph style={{ margin: 0 }}>
                        {el.vendorCode}
                      </Paragraph>
                    </div>
                  )}
                  {el.type === "customer" && (
                    <div>
                      <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                        Customer Name
                      </Paragraph>
                      <Paragraph style={{ margin: 0 }}>
                        {el.customerName}
                      </Paragraph>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          );
        })
      )}
    </div>
  );
};

export default OrderDataCard;
