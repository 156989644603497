import React, { useEffect, useState } from "react";
import "./App.scss";
import AllRoutes from "./Routes/AllRoutes";
import Navbar from "./components/Navbar/Navbar";
import { LoadingProvider } from "./components/Loader/LoadingContext";
import config from "./config.json";
import Offline from "./components/Offline/Offline";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { useLocation } from "react-router-dom";
import HomeNav from "./Pages/Home-components/HomeNav/HomeNav";
import { messaging, requestForToken } from "./firebase";
import { onMessage } from "firebase/messaging";
import { StompSessionProvider } from "react-stomp-hooks";
import {
  DATokenNotification,
  ManagerTokenNotification,
} from "./Services/Api/ApiFile";

const AppContent = () => {
  let audio = new Audio("/notification_alarm.mp3");
  const location = useLocation();
  const [isHomePage, setIsHomePage] = useState(false);
  const token = JSON.parse(localStorage.getItem("token"));
  const userName = localStorage.getItem("username");
  const noNavbarRoutes = ["/"];
  const showNavbar = !noNavbarRoutes.includes(location.pathname);

  const playSound = () => {
    audio.play().catch((error) => console.log("Audio play failed:", error));
  };

  const stopSound = () => {
    audio.pause();
    audio.currentTime = 0; // Reset the audio to the beginning
  };

  useEffect(() => {
    // Define the routes where HomeNav should be displayed
    const homeNavRoutes = ["/", "/contact-us", "/privacyPolicy"];
    setIsHomePage(homeNavRoutes.includes(location.pathname));

    // scroll to top when path changes
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // useEffect(() => {
  //   onMessageListener()
  //     .then((payload) => {
  //       console.log("Message received. ", payload);
  //       // Handle foreground notifications (e.g., show a notification toast)
  //       if (payload && token) {
  //         playSound();
  //         if (Notification.permission === "granted") {
  //           const notify = new Notification("New Delivery request received");
  //           notify.onclick = () => {
  //             stopSound();
  //             window.focus();
  //           };
  //         }
  //       }
  //     })
  //     .catch((err) => console.log("Failed to receive message: ", err));
  // }, []);

  useEffect(() => {
    if (token) {
      requestForToken()
        .then((fcmToken) => {
          const data = {
            name: userName,
            token: fcmToken,
          };
          console.log("body", data);
          if (fcmToken) {
            if (userName === "Station") {
              try {
                const res = ManagerTokenNotification(data);
                console.log("api firebase res", res.data);
              } catch (error) {
                console.log(error.message);
              }
            }
            if (token.role === "delivery associate") {
              try {
                const res = DATokenNotification(data);
                console.log("api firebase res", res.data);
              } catch (error) {
                console.log(error.message);
              }
            }
            console.log("Firebase Token in App.js:", fcmToken);
          } else {
            console.error("Failed to retrieve Firebase Token");
          }
        })
        .catch((err) => {
          console.error("Error in retrieving FCM Token: ", err);
        });
    }

    onMessage(messaging, (payload) => {
      console.log("payload messaging check ", payload);
      playSound();
      if (Notification.permission === "granted") {
        if (payload && token) {
          const notify = new Notification(payload.notification.title, {
            body: payload.notification.body,
            icon: "/Rover-Favicon.png",
          });
          notify.onclick = () => {
            stopSound();
            window.focus();
          };
        }
      }
    });
  }, [token]);

  useEffect(() => {
    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    }
  }, []);
  return (
    <div className="App">
      {isHomePage ? <HomeNav /> : showNavbar && <Navbar />}
      <div>
        <AllRoutes />
      </div>
    </div>
  );
};

function App() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    serviceWorkerRegistration.register({
      onUpdate: (registration) => {
        registration.waiting?.postMessage({ type: "SKIP_WAITING" });
      },
    });

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <StompSessionProvider url={config.webSocket}>
      <LoadingProvider>
        {isOnline ? <AppContent /> : <Offline />}
      </LoadingProvider>
    </StompSessionProvider>
  );
}

export default App;
