import { Card, Col, Row, Steps, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LoadingContext } from "../../../components/Loader/LoadingContext";
import {
  B2COrderData,
  GetOrderTrackingHistory,
} from "../../../Services/Api/ApiFile";
import dayjs from "dayjs";
const { Paragraph } = Typography;
const { Step } = Steps;

const EntireOrderData = () => {
  const { id, orderId } = useParams();
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const [stepData, setStepData] = useState([]);
  const [orderData, setOrderData] = useState({});
  console.log(id, orderId);
  const OrderTrackingStatus = async () => {
    try {
      startLoading();
      const res = await GetOrderTrackingHistory(orderId);
      console.log(res.data);
      setStepData(res.data);
    } catch (err) {
      console.log(err.message);
    } finally {
      stopLoading();
    }
  };

  const b2cOrderData = async () => {
    try {
      const res = await B2COrderData(orderId);
      setOrderData(res.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    if (orderId) {
      OrderTrackingStatus();
    }
    b2cOrderData();
  }, [orderId]);
  return (
    <div className="OrderData">
      <Typography.Title level={3} className="heading">
        Order Details
      </Typography.Title>
      <Card>
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <Card>
              <Row gutter={[16, 0]}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Order Id
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>{orderId}</Paragraph>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Station Code
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    {orderData.stationCode ? orderData.stationCode : "N.A"}
                  </Paragraph>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Pickup Name
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    {orderData.pickupName}
                  </Paragraph>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Pickup Number
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    {orderData.pickupNumber}
                  </Paragraph>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Pickup Address
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    {orderData.pickupAddress}
                  </Paragraph>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Packet Info
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    {orderData.packetInfo}
                  </Paragraph>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Total Amount
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    {orderData.totalAmount}
                  </Paragraph>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Collected Amount
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    {orderData.collectedAmount ? orderData.collectedAmount : 0}
                  </Paragraph>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Variance
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    {orderData.collectedAmount - orderData.depositedAmount}
                  </Paragraph>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Type
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>{orderData.type}</Paragraph>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Vendor code
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    {orderData.vendorCode}
                  </Paragraph>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Drop Name
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    {orderData.dropName}
                  </Paragraph>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Drop Number
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    {orderData.dropNumber}
                  </Paragraph>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Drop Address
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    {orderData.dropAddress}
                  </Paragraph>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Packet weight
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    {orderData.weight} {orderData.units}
                  </Paragraph>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Payment Type
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    {orderData.paymentType ? orderData.paymentType : "N.A"}
                  </Paragraph>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Deposited Amount
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    {orderData.depositedAmount ? orderData.depositedAmount : 0}
                  </Paragraph>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Vehicle Type
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    {orderData.vehicleType}
                  </Paragraph>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <Card className="trackingStatus">
              <Typography.Title level={3} className="heading">
                Tracking status
              </Typography.Title>
              <Steps direction="vertical" current={stepData.length - 1}>
                {stepData.map((step, index) => (
                  <Step
                    key={index}
                    title={step.deliveryStatus}
                    description={
                      <div style={{ margin: 0 }}>
                        <Paragraph style={{ margin: 0 }}>
                          Action Date:{" "}
                          {dayjs(step.actionDateTime).format("DD-MM-YYYY")}
                        </Paragraph>
                        <Paragraph style={{ margin: 0 }}>
                          Action Time:{" "}
                          {dayjs(step.actionDateTime).format("h:mm A")}
                        </Paragraph>
                        <Paragraph style={{ margin: 0 }}>
                          Action By: {step.actionby}
                        </Paragraph>
                      </div>
                    }
                  />
                ))}
              </Steps>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default EntireOrderData;
