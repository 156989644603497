import React from "react";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Button, Card, Divider, Input, Typography } from "antd";
import * as yup from "yup";
import { useFormik } from "formik";
import "./Login.scss";
import { loginServices } from "../Services/Api/ApiFile";
import { toast } from "react-toastify";

const Login = () => {
  const schema = yup
    .object({
      username: yup.string().required("This Field is Required"),
      password: yup.string().required("This Field is Required"),
    })
    .required();

  const error = () => {
    toast.error("Username or Password Incorrect", {
      position: "top-right",
    });
  };

  const success = () => {
    toast.success("Login Sucessfull", {
      position: "top-right",
    });
  };

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
  } = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: schema,
    onSubmit,
  });

  async function onSubmit(values, actions) {
    console.log(values);
    try {
      const { data } = await loginServices(values);
      if (data) {
        localStorage.setItem("token", JSON.stringify(data));
        localStorage.setItem("username", values.username);

        if (JSON.parse(localStorage.getItem("token")) || []) {
          if (JSON.parse(localStorage.getItem("token")).role === "member") {
            window.location.href = "/deliveryRequest";
            success();
          }
          if (
            JSON.parse(localStorage.getItem("token")).role ===
            "delivery associate"
          ) {
            window.location.href = "/orderList";
            success();
          }
        }
      }
    } catch (err) {
      console.log("Something went wrong", err.message);
      error();
    }
  }
  return (
    <div className="LoginContainer">
      <form className="LoginForm" onSubmit={handleSubmit}>
        {/* <b
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "21px",
            textAlign: "center",
            color: "red",
          }}
        >
          ********************Site under maintenance*******************
        </b> */}
        <Card
          style={{
            width: "100%",
          }}
        >
          <Typography.Title
            level={3}
            style={{
              margin: 0,
              textAlign: "center",
            }}
          >
            Sign In
          </Typography.Title>
          <Divider />
          <div className="inputbox">
            <Typography.Title
              level={5}
              style={{
                margin: 0,
              }}
            >
              UserName
            </Typography.Title>

            <Input
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.username}
              name="username"
              id="username"
              placeholder="Enter username"
              // error={touched.username && Boolean(errors.username)}
              // helperText={touched.username && errors.username}
            />
            {errors.username && touched.username && (
              <span style={{ color: "red" }}>{errors.username}</span>
            )}
          </div>
          <div className="inputbox">
            <Typography.Title
              level={5}
              style={{
                margin: 0,
              }}
            >
              Password
            </Typography.Title>

            <Input.Password
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              name="password"
              id="password"
              placeholder="Enter password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              // error={touched.password && Boolean(errors.password)}
              // helperText={touched.password && errors.password}
            />
            {errors.password && touched.password && (
              <span style={{ color: "red" }}>{errors.password}</span>
            )}
          </div>
          <div className="loginBtn">
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%", backgroundColor: "#ff6a1a" }}
            >
              Sign In
            </Button>
          </div>
        </Card>
      </form>
    </div>
  );
};

export default Login;
