import { Button, Card, DatePicker, Table, Typography } from "antd";
import dayjs from "dayjs";
import { json2csv } from "json-2-csv";
import FileSaver from "file-saver";
import React, { useEffect, useState } from "react";
import { GrDocumentCsv } from "react-icons/gr";
import { B2cAmountListingData } from "../../../Services/Api/ApiFile";

const AmountListing = () => {
  const [amountListing, setAmountListing] = useState([]);
  const [pageSize, setPageSize] = useState(6); // Page size state
  const [currentPage, setCurrentPage] = useState(1);
  const [selectDate, setSelectDate] = useState(dayjs().format("DD-MM-YYYY"));
  const [overallCollectedAmount, setOverallCollectedAmount] = useState(0);
  const token = JSON.parse(localStorage.getItem("token"));
  const dateFormatList = ["DD-MM-YYYY"];
  console.log("selectdate", selectDate);

  const columns = [
    {
      title: "Booking Id",
      dataIndex: "orderId",
      key: "orderId",
      responsive: ["xs", "sm", "md", "lg", "xl"],
    },
    {
      title: "Delivered At",
      dataIndex: "deliveryAt",
      key: "deliveryAt",
      responsive: ["xs", "sm", "md", "lg", "xl"],
      render: (text, record) => (
        <div>{text ? dayjs(text).format("DD-MM-YYYY") : "N.A"}</div>
      ),
    },
    {
      title: "TotalAmount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      responsive: ["xs", "sm", "md", "lg", "xl"],
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      key: "paymentType",
      responsive: ["xs", "sm", "md", "lg", "xl"],
    },
    {
      title: "Collected Amount",
      dataIndex: "collectedAmount",
      key: "collectedAmount",
      responsive: ["xs", "sm", "md", "lg", "xl"],
    },
  ];
  const handleDownloadCSV = async () => {
    const dataToConvert = amountListing.map((el) => ({
      OrderId: el.orderId,
      DeliveredAt: el.deliveryAt,
      PaymentType: el.paymentType,
      TotalAmount: el.totalAmount,
      CollectedAmount: el.collectedAmount,
    }));

    const options = {
      keys: [
        "OrderId",
        "DeliveredAt",
        "PaymentType",
        "TotalAmount",
        "CollectedAmount",
      ],
      delimiter: {
        wrap: '"', // Optional wrapping of values
        field: ",", // Field delimiter
        array: ";", // Array value delimiter
      },
    };

    try {
      const csv = json2csv(dataToConvert, options);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      FileSaver.saveAs(blob, "amountListing.csv");
    } catch (error) {
      console.error("Error generating CSV:", error);
    }
  };

  const handleTableChange = (pagination) => {
    setPageSize(pagination.pageSize);
    setCurrentPage(pagination.current);
  };

  const AmountListingData = async () => {
    try {
      const res = await B2cAmountListingData(
        dayjs(selectDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
        token.userId
      );
      const totalCollectedAmount = res.data.reduce((acc, item) => {
        return acc + (Number(item.collectedAmount) || 0);
      }, 0);
      setAmountListing(res.data);
      setOverallCollectedAmount(totalCollectedAmount);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    AmountListingData();
  }, [selectDate]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Typography.Title level={3} className="heading">
          Amount Listing
        </Typography.Title>
        <DatePicker
          id="SelectDate"
          name="SelectDate"
          value={dayjs(selectDate, "DD-MM-YYYY")}
          onChange={(date) => {
            if (date) {
              setSelectDate(dayjs(date).format("DD-MM-YYYY"));
            } else {
              setSelectDate(null);
            }
          }}
          placeholder="Selected Date"
          format={dateFormatList}
          allowClear={false}
        />

        <Button className="downloadCSVbtn" onClick={handleDownloadCSV}>
          Download
          <GrDocumentCsv style={{ fontSize: "20px" }} />
        </Button>
      </div>

      <div className="amountList-mainBox" style={{ marginTop: "10px" }}>
        <Card>
          <Table
            columns={columns}
            dataSource={amountListing}
            rowKey="id"
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              pageSizeOptions: ["6", "10", "15", "20"],
              showSizeChanger: true,
            }}
            onChange={handleTableChange}
            className="amountList-table"
            //   onRow={(record) => ({
            //     onClick: () => {
            //       console.log(record);
            //     },
            //   })}
          />
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            <Typography.Text strong>Overall Collected Amount: </Typography.Text>
            <Typography.Text>{overallCollectedAmount}</Typography.Text>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default AmountListing;
