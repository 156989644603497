import React, { useContext, useEffect, useState } from "react";
import { Col, Image, Row, Typography, Input, Button } from "antd";
import moment from "moment";
import { json2csv } from "json-2-csv";
import FileSaver from "file-saver";
import ThreeWheeler from "../../images/three wheeler 1.png";
import TwoWheeler from "../../images/motorcycle 1.png";
import FourWheeler from "../../images/express-delivery 1.png";
import "./DeliveryRequest.scss";
import IndividualProductModal from "./IndividualProductModal";
import { deliveryRequests } from "../../Services/Api/ApiFile";
import { LoadingContext } from "../../components/Loader/LoadingContext";
import NoData from "../../components/NoData/NoData";
import { GrDocumentCsv } from "react-icons/gr";
import { useSubscription } from "react-stomp-hooks";
const { Paragraph } = Typography;
const { Search } = Input;

const DeliveryRequest = () => {
  const [deliveryRequest, setDeliveryRequest] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDelivery, setSelectedDelivery] = useState({});
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const [searchValue, setSearchValue] = useState("");

  const handleRowClick = async (request) => {
    // console.log(" selecsdelivery", request);
    const formattedDelivery = formatSelectedDeliveryDates(request);
    setSelectedDelivery(formattedDelivery);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const formattedDate = (date) => {
    const dob = moment(date);
    const formattedDob = dob.format("DD-MM-YYYY");
    return formattedDob;
  };

  const formatSelectedDeliveryDates = (delivery) => {
    // console.log(delivery);
    return {
      ...delivery,
      deliveryDate: moment(delivery.deliveryDate).format("DD-MM-YYYY"),
      pickupdate: moment(delivery.pickupdate).format("DD-MM-YYYY"),
    };
  };
  const GetDeliveryRequests = async () => {
    try {
      startLoading();
      const res = await deliveryRequests();
      // console.log("delivrydata", res);
      const sortedRequests = res.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setDeliveryRequest(sortedRequests);
    } catch (err) {
      console.log(err.message);
    } finally {
      stopLoading();
    }
  };

  const handleDownloadCSV = async () => {
    const dataToConvert = deliveryRequest.map((request) => ({
      PacketInfo: request.packetInfo,
      OrderId: request.orderId,
      DeliveryDate: formattedDate(request.deliveryDate),
      DeliveryTimeSlot: request.deliveryTimeSlot,
      PickupDate: formattedDate(request.pickupdate),
      PickupSlot: request.pickupSlot,
      Weight: request.weight,
      Units: request.units,
      CustomerMobileNumber: request.customerMobileNo,
      VehicleType: request.vehicleType,
      VendorCode: request.vendorCode,
      TotalAmount: request.totalAmount,
    }));

    const options = {
      keys: [
        "PacketInfo",
        "OrderId",
        "DeliveryDate",
        "DeliveryTimeSlot",
        "PickupDate",
        "PickupSlot",
        "Weight",
        "Units",
        "CustomerMobileNumber",
        "VehicleType",
        "VendorCode",
        "TotalAmount",
      ],
      delimiter: {
        wrap: '"', // Optional wrapping of values
        field: ",", // Field delimiter
        array: ";", // Array value delimiter
      },
    };

    try {
      const csv = json2csv(dataToConvert, options);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      FileSaver.saveAs(blob, "delivery_requests.csv");
    } catch (error) {
      console.error("Error generating CSV:", error);
    }
  };

  let websocketurl = "/topic/requestmessage";
  useSubscription(websocketurl, (message) => {
    console.log("websocket message", message);
    GetDeliveryRequests();
  });

  useEffect(() => {
    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    }
    GetDeliveryRequests();
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Typography.Title level={3} className="heading">
          Delivery Request
        </Typography.Title>
        <Search
          placeholder="Search by OrderId"
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          style={{ width: 200 }}
        />
        <Button className="downloadCSVbtn" onClick={handleDownloadCSV}>
          Download
          <GrDocumentCsv style={{ fontSize: "20px" }} />
        </Button>
      </div>
      <div className="deliveryReq-mainBox">
        {deliveryRequest.length === 0 ? (
          <NoData />
        ) : (
          deliveryRequest
            .filter((item) => {
              const searchTerm = searchValue.toLowerCase();
              return item.orderId.toLowerCase().includes(searchTerm);
            })
            .map((el, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    // console.log("row", el);
                    handleRowClick(el);
                  }}
                >
                  <Row
                    style={{
                      backgroundColor: "rgba(244, 109, 37, 0.12)",
                      borderRadius: "5px",
                      marginTop: index === 0 ? 0 : "10px",
                      padding: "10px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Typography.Text style={{ margin: 0, fontWeight: 600 }}>
                        {el.packetInfo} ({el.orderId})
                      </Typography.Text>
                    </Col>
                    <Col xs={8} sm={8} md={6} lg={6} xl={6}>
                      <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                        Delivery Date
                      </Paragraph>
                      <Paragraph style={{ margin: 0 }}>
                        {formattedDate(el.deliveryDate)}
                      </Paragraph>
                      <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                        Delivery Slot
                      </Paragraph>
                      <Paragraph style={{ margin: 0 }}>
                        {el.deliveryTimeSlot}
                      </Paragraph>
                    </Col>
                    <Col xs={8} sm={8} md={6} lg={6} xl={6}>
                      <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                        Pickup Date
                      </Paragraph>
                      <Paragraph style={{ margin: 0 }}>
                        {formattedDate(el.pickupdate)}
                      </Paragraph>
                      <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                        Pickup Slot
                      </Paragraph>
                      <Paragraph style={{ margin: 0 }}>
                        {el.pickupSlot}
                      </Paragraph>
                    </Col>
                    <Col
                      className="nameAndNumber"
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                        Weight
                      </Paragraph>
                      <Paragraph style={{ margin: 0 }}>
                        {el.weight} {el.units}
                      </Paragraph>
                      <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                        Customer Number
                      </Paragraph>
                      <Paragraph style={{ margin: 0 }}>
                        {el.customerMobileNo}
                      </Paragraph>
                    </Col>
                    <Col xs={8} sm={8} md={6} lg={6} xl={6}>
                      {/* <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                      Customer Number
                    </Paragraph>
                    <Paragraph style={{ margin: 0 }}>
                      {el.customerMobileNo}
                    </Paragraph> */}
                      {el.vehicleType === "Three Wheeler" && (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Image
                            src={ThreeWheeler}
                            alt="icon"
                            preview={false}
                          />
                        </div>
                      )}
                      {el.vehicleType === "Two Wheeler" && (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Image src={TwoWheeler} alt="icon" preview={false} />
                        </div>
                      )}
                      {el.vehicleType === "Four Wheeler" && (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Image src={FourWheeler} alt="icon" preview={false} />
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              );
            })
        )}
      </div>
      <IndividualProductModal
        isModalOpen={isModalOpen}
        selectedDelivery={selectedDelivery}
        handleCancel={handleCancel}
        setIsModalOpen={setIsModalOpen}
        GetDeliveryRequests={GetDeliveryRequests}
      />
    </div>
  );
};

export default DeliveryRequest;
