// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyClLRBEXUo3h7dIvDv9eATQu0yF3cNNdUU",
  authDomain: "vitran-pwa.firebaseapp.com",
  projectId: "vitran-pwa",
  storageBucket: "vitran-pwa.appspot.com",
  messagingSenderId: "607075361809",
  appId: "1:607075361809:web:428ef903e0106fb9d47db0",
  measurementId: "G-8RSXP6GHVQ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const requestForToken = async () => {
  console.log("requestForToken called");

  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const currentToken = await getToken(messaging, {
        vapidKey:
          "BCQhdrP_xEkThUuZylFOw4UUuRqBbtAgOj8PUC735P5MjpSMEF4BmEUXrjkxfHT3eTR5cusTP0a8On7XCHdHFZc",
      });

      if (currentToken) {
        console.log("FCM Token fetched:", currentToken);
        return currentToken;
      } else {
        console.error("No registration token available.");
        return null;
      }
    } else {
      console.error("Notification permission not granted.");
      return null;
    }
  } catch (err) {
    console.error("An error occurred while retrieving the token:", err);
    return null;
  }
};

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       console.log("payload", payload);
//       resolve(payload);
//     });
//   });

// *******************vitran google firebase ********************

// const firebaseConfig = {
//   apiKey: "AIzaSyClLRBEXUo3h7dIvDv9eATQu0yF3cNNdUU",
//   authDomain: "vitran-pwa.firebaseapp.com",
//   projectId: "vitran-pwa",
//   storageBucket: "vitran-pwa.appspot.com",
//   messagingSenderId: "607075361809",
//   appId: "1:607075361809:web:428ef903e0106fb9d47db0",
//   measurementId: "G-8RSXP6GHVQ"
// };
// vapid key: "BCQhdrP_xEkThUuZylFOw4UUuRqBbtAgOj8PUC735P5MjpSMEF4BmEUXrjkxfHT3eTR5cusTP0a8On7XCHdHFZc"
