import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import dayjs from "dayjs";
import "./OrderStatus.scss";
import { json2csv } from "json-2-csv";
import FileSaver from "file-saver";
import { DeliveryOrderStatus } from "../../Services/Api/ApiFile";
import { LoadingContext } from "../../components/Loader/LoadingContext";
import DeliveryStatusTable from "./DeliveryStatusTabke";
import { GrDocumentCsv } from "react-icons/gr";

const OrderStatus = () => {
  const schema = yup
    .object({
      orderSelectDate: yup.date().required("This Field is Required"),
      orderStatus: yup.string().required("This Field is Required"),
    })
    .required();
  const [deliveryOrderStatus, setDeliveryOrderStatus] = useState([]);
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const dateFormatList = ["DD-MM-YYYY", "DD-MM-YY", "DD-MM-YYYY", "DD-MM-YY"];
  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      orderSelectDate: dayjs(),
      orderStatus: "Assigned",
    },
    validationSchema: schema,
    onSubmit,
  });

  async function onSubmit(values, actions) {
    const formattedValues = {
      ...values,
      orderSelectDate: dayjs(values.orderSelectDate).format("YYYY-MM-DD"),
    };
    // console.log("Form submitted with values:", formattedValues);
    try {
      startLoading();
      const res = await DeliveryOrderStatus(
        formattedValues.orderSelectDate,
        formattedValues.orderStatus
      );
      setDeliveryOrderStatus(res.data);
    } catch (error) {
      console.log(error.message);
    } finally {
      stopLoading();
    }
  }

  const handleDownloadCSV = async () => {
    const dataToConvert = deliveryOrderStatus.map((orderStatus) => ({
      PacketInfo: orderStatus.packetInfo,
      OrderId: orderStatus.orderId,
      DeliveryDate: dayjs(orderStatus.deliveryDate).format("DD-MM-YYYY"),
      DeliveryTimeSlot: orderStatus.deliveryTimeSlot,
      PickupDate: dayjs(orderStatus.pickupdate).format("DD-MM-YYYY"),
      PickupSlot: orderStatus.pickupSlot,
      Weight: orderStatus.weight,
      Units: orderStatus.units,
      CustomerMobileNumber: orderStatus.customerMobileNo,
      VehicleType: orderStatus.vehicleType,
      VendorCode: orderStatus.vendorCode,
      TotalAmount: orderStatus.totalAmount,
      DeliveryStatus: orderStatus.deliverystatus,
      AssignPersonName: orderStatus.assignPersonName,
      DepositedAmount: orderStatus.depositedAmount,
      Remark: orderStatus.remark,
    }));

    const options = {
      keys: [
        "PacketInfo",
        "OrderId",
        "DeliveryDate",
        "DeliveryTimeSlot",
        "PickupDate",
        "PickupSlot",
        "Weight",
        "Units",
        "CustomerMobileNumber",
        "VehicleType",
        "VendorCode",
        "TotalAmount",
        "DepositedAmount",
        "AssignPersonName",
        "DeliveryStatus",
        "Remark",
      ],
      delimiter: {
        wrap: '"', // Optional wrapping of values
        field: ",", // Field delimiter
        array: ";", // Array value delimiter
      },
    };

    try {
      const csv = json2csv(dataToConvert, options);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      FileSaver.saveAs(blob, "orderStatus.csv");
    } catch (error) {
      console.error("Error generating CSV:", error);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, [values.orderSelectDate, values.orderStatus]);

  return (
    <div>
      <Row style={{ margin: 0 }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Typography.Title level={3} className="heading">
            Order Status
          </Typography.Title>
          <Button className="downloadCSVbtn" onClick={handleDownloadCSV}>
            Download
            <GrDocumentCsv style={{ fontSize: "20px" }} />
          </Button>
        </Col>
        <Col
          className="date_And_ordestatus"
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
        >
          <Form.Item
            label="Select Date"
            layout="vertical"
            validateStatus={
              errors.orderSelectDate && touched.orderSelectDate ? "error" : ""
            }
            help={
              errors.orderSelectDate && touched.orderSelectDate
                ? errors.orderSelectDate
                : null
            }
            style={{ fontWeight: 500 }}
          >
            <DatePicker
              id="orderSelectDate"
              name="orderSelectDate"
              // value={dayjs(values.orderSelectDate, dateFormatList[0])}
              value={dayjs(values.orderSelectDate)}
              onChange={(date) => {
                setFieldValue("orderSelectDate", date);
                // console.log(date);
              }}
              placeholder="Order Selected Date"
              format={dateFormatList}
              style={{ width: "100%" }}
              allowClear={false}
            />
          </Form.Item>
        </Col>
        <Col
          className="date_And_ordestatus"
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
        >
          <Form.Item
            label="Order Status"
            layout="vertical"
            validateStatus={
              errors.orderStatus && touched.orderStatus ? "error" : ""
            }
            help={
              errors.orderStatus && touched.orderStatus
                ? errors.orderStatus
                : null
            }
            style={{ fontWeight: 500 }}
          >
            <Select
              name="orderStatus"
              id="orderStatus"
              defaultValue="Assigned"
              value={values.orderStatus}
              style={{ width: "100%" }}
              onChange={(value) => setFieldValue("orderStatus", value)}
              onBlur={handleBlur}
            >
              {[
                { value: "All", label: "All" },
                { value: "Assigned", label: "Assigned" },
                { value: "Cancelled", label: "Cancelled" },
                { value: "Pickup", label: "Pickedup" },
                { value: "Delivered", label: "Delivered" },
              ].map((status, id) => (
                <Select.Option key={id} value={status.value}>
                  {status.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Card>
        <DeliveryStatusTable
          deliveryOrderStatus={deliveryOrderStatus}
          orderStatus={values.orderStatus}
        />
      </Card>
    </div>
  );
};

export default OrderStatus;
