import { Button, Col, Row, Select, Typography, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  ApprovedOrderDelivery,
  DeliveryAssignToDA,
  StationDeliveryAssociates,
} from "../../Services/Api/ApiFile";
import moment from "moment";
import "./AssignToDa.scss";
import { LoadingContext } from "../../components/Loader/LoadingContext";
import NoData from "../../components/NoData/NoData";
const { Paragraph } = Typography;

const AssignDA = () => {
  const [approveRequest, setApproveRequest] = useState([]);
  const [deliveryAssistants, setDeliveryAssistants] = useState([]);
  const [selectedDA, setSelectedDA] = useState({});
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const token = JSON.parse(localStorage.getItem("token"));
  const ApprovedDeliveryData = async () => {
    try {
      startLoading();
      const res = await ApprovedOrderDelivery();
      setApproveRequest(res.data);
    } catch (err) {
      console.log(err.message);
    } finally {
      stopLoading();
    }
  };

  const formattedDate = (date) => {
    const dob = moment(date);
    const formattedDob = dob.format("DD-MM-YYYY");
    return formattedDob;
  };

  const GetDeliveryAssistants = async () => {
    try {
      const res = await StationDeliveryAssociates();
      // console.log(res.data);
      setDeliveryAssistants(res.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const AssignDA = async (orderId) => {
    // console.log("orderId", orderId);
    const data = {
      orderId: orderId,
      assignPersonId: selectedDA?.id,
      assignPersonName: selectedDA?.username,
      assignBy: token.userId,
    };
    // console.log("assignDa", data);
    try {
      const res = await DeliveryAssignToDA(data);
      // const res = true;
      if (res) {
        ApprovedDeliveryData();
        message.success(`Assigned to DA ${selectedDA.username}`);
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  // console.log("selectedda", selectedDA);

  const onChange = (value) => {
    const parsedValue = JSON.parse(value);
    // console.log(`selected ${parsedValue}`);
    setSelectedDA(parsedValue);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  useEffect(() => {
    ApprovedDeliveryData();
    GetDeliveryAssistants();
  }, []);
  return (
    <div>
      {/* <Typography.Title level={3} className="heading">
        Assign To DA
      </Typography.Title> */}
      <div className="AssignDa-mainBox">
        {approveRequest.length === 0 ? (
          <NoData />
        ) : (
          approveRequest.map((el, index) => {
            return (
              <Row
                style={{
                  backgroundColor: "rgba(244, 109, 37, 0.12)",
                  borderRadius: "5px",
                  marginTop: index === 0 ? 0 : "10px",
                  padding: "10px",
                  alignItems: "center",
                }}
                key={index}
              >
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Typography.Text style={{ margin: 0, fontWeight: 600 }}>
                    {el.packetInfo} ({el.orderId})
                  </Typography.Text>
                </Col>
                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Delivery Date
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    {formattedDate(el.deliveryDate)}
                  </Paragraph>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Delivery Slot
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    {el.deliveryTimeSlot}
                  </Paragraph>
                </Col>
                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Pickup Date
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    {formattedDate(el.pickupdate)}
                  </Paragraph>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Pickup Slot
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>{el.pickupSlot}</Paragraph>
                </Col>
                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Assigning DA
                  </Paragraph>
                  <Select
                    showSearch
                    placeholder="Select a person"
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearch}
                    style={{ width: "90%" }}
                  >
                    {deliveryAssistants.map((el, id) => (
                      <Select.Option
                        key={id}
                        value={JSON.stringify({
                          username: el.username,
                          id: el.id,
                        })}
                      >
                        {el.username}
                      </Select.Option>
                    ))}
                  </Select>
                  <div>
                    <Button
                      type="primary"
                      style={{ width: "90%", backgroundColor: "#ff6a1a" }}
                      iconPosition={"start"}
                      onClick={() => AssignDA(el.orderId)}
                    >
                      Assign To DA
                    </Button>
                  </div>
                </Col>
              </Row>
            );
          })
        )}
      </div>
    </div>
  );
};

export default AssignDA;
