import http from "../httpService";
import config from "../../config.json";

const tokens = JSON.parse(localStorage.getItem("token"));
const token = tokens?.jwttoken;

const head = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "access-control-allow-origin": "*",
    Authorization: `Bearer ${token}`,
  },
};
const head2 = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "multipart/form-data",
    "access-control-allow-origin": "*",
    Authorization: `Bearer ${token}`,
  },
};

export function getImage(src) {
  return http.get(config.imageUrl + src, {
    ...head,
    responseType: "blob",
  });
}

export function loginServices(data) {
  return http.post(config.apiUrl + "/login", data);
}

export function deliveryRequests() {
  return http.get(config.apiUrl + "/b2corderstatus/Requested", head);
}

export function IndividualRowDeliveryData(orderId) {
  return http.get(config.apiUrl + `/b2corderdetails/${orderId}`, head);
}

export function AcceptTheDelivery(data) {
  return http.post(config.apiUrl + "/b2capproveorder", data, head);
}

export function CancelTheDelivery(data) {
  return http.post(config.apiUrl + "/b2ccancelorder", data, head);
}

export function ApprovedOrderDelivery() {
  return http.get(config.apiUrl + "/b2corderstatus/Approved", head);
}

export function AssignedDAList() {
  return http.get(config.apiUrl + "/b2corderstatus/Assigned", head);
}

export function StationDeliveryAssociates() {
  return http.get(
    config.apiUrl + `/stationdeliveryassociate/${tokens?.stationCode}`,
    head
  );
}

export function DeliveryAssignToDA(data) {
  return http.post(config.apiUrl + "/b2cassignorder", data, head);
}

export function DeliveryOrderStatus(datevalue, deliverystatus) {
  return http.get(
    config.apiUrl + `/b2corderdatestatus/${datevalue}/${deliverystatus}`,
    head
  );
}

export function GetOrderTrackingHistory(orderId) {
  return http.get(config.apiUrl + `/b2c/orderHistoryTimeline/${orderId}`, head);
}

export function GetDefaultRunSheetList() {
  return http.get(config.apiUrl + "/b2c/getRunsheetClosureDefaultList", head);
}

export function GetRunSheetListWithDate(date) {
  return http.get(config.apiUrl + `/b2c/getRunsheetClosureList/${date}`, head);
}

export function ChangeDeliveryStatusOfManager(data) {
  return http.post(config.apiUrl + "/b2c/updateStatus", data, head);
}

export function GetDACashReconcileList(assignPersonId, date, deliverystatus) {
  return http.get(
    config.apiUrl + `/b2cDAList/${assignPersonId}/${date}/${deliverystatus}`,
    head
  );
}

export function GetDaIndividualDataByIdandDate(date, assignPersonId) {
  return http.get(
    config.apiUrl + `/b2cstaffdeliverydateorder/${date}/${assignPersonId}`,
    head
  );
}

export function UpdateDepositAmountByDA(data) {
  return http.post(config.apiUrl + "/b2cDADeposite", data, head);
}

export function GetCashbackListApi() {
  return http.get(config.apiUrl + `/b2c/getCashbackList`, head);
}

export function UpdateCashbackPerApi(data) {
  return http.post(config.apiUrl + "/b2c/updateCashbackPer", data, head);
}

export function GetBannerImagesList() {
  return http.get(config.apiUrl + `/b2c/bannerImagesList`, head);
}

export function UploadBannerImages(formData) {
  return http.post(config.apiUrl + "/b2c/uploadBannerImages", formData, head2);
}

export function ActiveDeactiveApi(id) {
  return http.get(config.apiUrl + `/b2c/deactivateBannerImage/${id}`, head);
}

// *************************DA Api's**************************

export function b2cstafforder(daName) {
  return http.get(config.apiUrl + "/b2cstafforder/" + daName, head);
}
export function b2corderdetails(data) {
  return http.get(config.apiUrl + `/b2corderdetails/${data}`, head);
}
export function orderreachlocation(data) {
  return http.get(config.apiUrl + `/b2c/orderreachlocation/${data}`, head);
}
export function cancelorder(data) {
  return http.post(config.apiUrl + `/b2c/cancelorder`, data, head);
}
export function orderdapickup(data) {
  console.log("data: ", data.get("pickupImage"), data);
  return http.post(config.apiUrl + `/b2c/orderdapickup`, data, head2);
}
export function orderdadelivery(data) {
  console.log("data: ", data.get("pickupImage"), data);
  return http.post(config.apiUrl + `/b2c/orderdadelivery`, data, head2);
}
export function b2cstaffdateorder(date, da) {
  return http.get(
    config.apiUrl + `/b2cstaffdateorderstatus/${date}/${da}/Assigned`,
    head
  );
}
export function B2COrderData(orderId) {
  return http.get(config.apiUrl + `/b2corderdetails/${orderId}`, head);
}

export function b2cDaListWithDate(assignPersonId, dateValue, orderStatus) {
  return http.get(
    config.apiUrl +
      `/b2cstaffdateorderstatus/${dateValue}/${assignPersonId}/${orderStatus}`,
    head
  );
}

export function B2cAmountListingData(dateValue, assignPersonId) {
  return http.get(
    config.apiUrl + `/b2cstaffdeliverydateorder/${dateValue}/${assignPersonId}`,
    head
  );
}

// ***************************************Firebase Api*************************************
export function ManagerTokenNotification(data) {
  return http.post(
    config.apiUrl + `/b2c/saveManagerTokenForNotification`,
    data,
    head
  );
}

export function DATokenNotification(data) {
  return http.post(
    config.apiUrl + `/b2c/saveDaTokenForNotification`,
    data,
    head
  );
}

// ****************HomepageApi**************
export function Enquiry(data) {
  return http.post(config.apiUrl + `/sendEnquiry`, data);
}
