import React from "react";
import "./HomeNav.scss";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import vitranLogo from "../../../images/VitranLogo.png";
import { Button } from "antd";

const HomeNav = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLinkClick = () => {
    const checkbox = document.getElementById("homenav-sidebar-active");
    if (checkbox) {
      checkbox.checked = false;
    }
  };

  const isActiveLink = (path) => {
    return location.pathname === path ? "active-link" : "";
  };
  return (
    <div>
      <nav className="homeNavbar">
        <div className="homenav-head-Image">
          <img src={vitranLogo} alt="icon" onClick={() => navigate("/")} />
        </div>

        <input type="checkbox" id="homenav-sidebar-active" />
        <label
          htmlFor="homenav-sidebar-active"
          className="homenav-open-sidebar-button"
        >
          <MenuOutlined />
        </label>
        <div className="homenav-links-container">
          <label
            htmlFor="homenav-sidebar-active"
            className="homenav-close-sidebar-button"
          >
            <CloseOutlined />
          </label>

          <Link
            to="/login"
            className={`${isActiveLink("/login")} signIn`}
            onClick={handleLinkClick}
          >
            <Button className="mobileRoverloginbtn">
              <img src="/Rover-Favicon.png" alt="rovericon" /> Vitran Login
            </Button>
          </Link>

          <Link
            to="/contact-us"
            className={`${isActiveLink("/contact-us")} route-links`}
            onClick={handleLinkClick}
          >
            ContactUs
          </Link>
          {/* <div className="dropdown-mobile">
            {items.map((item) => (
              <Link to={`/${item.key}`} key={item.key}>
                {item.label}
              </Link>
            ))}
          </div> */}
        </div>
        <div className="Rover-loginbtndiv">
          <Button className="roverloginBtn" onClick={() => navigate("/login")}>
            <img src="/Rover-Favicon.png" alt="rovericon" />
            Login To Vitran
          </Button>
        </div>
      </nav>
    </div>
  );
};

export default HomeNav;
